import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFound = () => {
    return (
        <Layout>
            <Head title="404 -"/>
            <h1 style={{ textAlign: 'center' }}>Sorry, this page was not found.</h1>
            <p><Link to="/"
                     >Head home</Link></p>
        </Layout>
    )
}

export default NotFound